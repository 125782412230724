@import-normalize;


*{
    box-sizing: border-box;
}

img{
    margin: 0;
}
